.AppPage {
    background-color: #232328;
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AppPageContent {}

/* https://google-webfonts-helper.herokuapp.com/fonts/mulish?subsets=latin */
/* mulish-regular - latin_cyrillic */
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/mulish-v12-latin_cyrillic-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../../fonts/mulish-v12-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../../fonts/mulish-v12-latin_cyrillic-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../../fonts/mulish-v12-latin_cyrillic-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../../fonts/mulish-v12-latin_cyrillic-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../../fonts/mulish-v12-latin_cyrillic-regular.svg#Mulish') format('svg');
    /* Legacy iOS */
}

/* mulish-700 - latin_cyrillic */
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/mulish-v12-latin_cyrillic-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../../fonts/mulish-v12-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../../fonts/mulish-v12-latin_cyrillic-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../../fonts/mulish-v12-latin_cyrillic-700.woff') format('woff'),
        /* Modern Browsers */
        url('../../fonts/mulish-v12-latin_cyrillic-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../../fonts/mulish-v12-latin_cyrillic-700.svg#Mulish') format('svg');
    /* Legacy iOS */
}

/* mulish-900 - latin_cyrillic */
@font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/mulish-v12-latin_cyrillic-900.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../../fonts/mulish-v12-latin_cyrillic-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../../fonts/mulish-v12-latin_cyrillic-900.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../../fonts/mulish-v12-latin_cyrillic-900.woff') format('woff'),
        /* Modern Browsers */
        url('../../fonts/mulish-v12-latin_cyrillic-900.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../../fonts/mulish-v12-latin_cyrillic-900.svg#Mulish') format('svg');
    /* Legacy iOS */
}